<template>
  <form v-if="form" class="space-y-6 w-full pt-4">

      <div class="col-span-1">
          <Input placeholder="Pārvadātāja info, AM info" v-model="form.shipping_note" :errors="errors.shipping_note" />
      </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">


        <div class="col-span-1">
            <Input placeholder="Piegādes noteikumi" v-model="form.shipping_terms" :errors="errors.shipping_terms" />
        </div>

      <div class="col-span-1">
        <Input placeholder="Piegādes veids" v-model="form.shipping_method" :errors="errors.shipping_method" />
      </div>

      <div class="col-span-1">
        <Input type="number" placeholder="Piegādes izmaksas" v-model="form.shipping_cost" :errors="errors.shipping_cost" />
      </div>

      <div class="col-span-1">
        <Input type="number" placeholder="Piegādes cena klientam" v-model="form.shipping_price" :errors="errors.shipping_price" />
      </div>
      
      <div class="col-span-1">
        <Input placeholder="Tracking nr." v-model="form.tracking_nr" :errors="errors.tracking_nr" />
      </div>
    </div>

    <div class="pt-2">
      <div class="flex justify-end">
        <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="hideEditItemForm">
          Atcelt
        </button>

        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Input from "@/components/Components/Input";

export default {
  name: "EditOrderShippingDetails",
  components: {
    Loading,
    Input,
  },
  data: () => ({
    form: null
  }),
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  mounted() {
    this.form = this.order
  },
  computed: {
    ...mapGetters({
      mainCustomer: "systemMainCustomer",
      loading: "loading",
      errors: "errors",
    }),
    formValidation() {
      return {
      }
    }
  },
  methods: {
    hideEditItemForm() {
      this.$store.dispatch("removeAllFormsForDisplay");

      this.$store.dispatch("getSingleOrder", this.$route.params.orderId)
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('updateOrderShippingDetails', {
          id: this.order.id,
          data: {
            shipping_note: this.form.shipping_note,
            shipping_terms: this.form.shipping_terms,
            shipping_method: this.form.shipping_method,
            shipping_cost: this.form.shipping_cost,
            shipping_price: this.form.shipping_price,
            tracking_nr: this.form.tracking_nr,
          }
        })
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>

<style>

</style>